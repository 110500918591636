// batchPusherEvents.js

/**
 * Batch specific event data into an array by event names
 * @param {[string} types - event names need to batch
 * @param {[object]} events - origin events from pusher
 * @param {[object]} events
 */
export const batchPusherEvents = ({ types = [], events = [] }) => {
  if (!types?.length) return events;

  let tempBatchedData = {};
  const filteredEvents = events.reduce((accu, curr) => {
    if (types.includes(curr.event)) {
      if (!tempBatchedData[curr.event]) tempBatchedData[curr.event] = [];
      tempBatchedData[curr.event].push(curr.data);
    } else accu.push(curr);

    return accu;
  }, []);
  return [
    ...filteredEvents,
    ...types
      .filter(type => tempBatchedData?.[type]?.length)
      .map(type => {
        return {
          event: type,
          data: {
            batchedData: tempBatchedData[type],
          },
        };
      }),
  ];
};

export default batchPusherEvents;
